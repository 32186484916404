<div id="dstar-uses" class="who-we-are-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Advantages of <span> DXPERTS Professional Network</span></h2>
            <p>Be a life time memeber of global crypto community & develop networking among all existing & future of crypto projects. A distributed networking, 
                communication & engagement platform & a think tank to witness past, present and future of DLT revolution.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-users"></i>
                    <h3>Life Time Membership</h3>
                    <p> Postion yourself as an Expert.</p>
                    <span>1</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-book"></i>
                    <h3>Access to Resources</h3>
                    <p>Access to unlimited Resources.</p>
                    <span>2</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-handshake"></i>
                    <h3>Networking & Events</h3>
                    <p>Exposure to Networking Events.</p>
                    <span>3</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-project-diagram"></i>
                    <h3>Project Collaborations</h3>
                    <p>Receive project & collaborations offers.</p>
                    <span>4</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-newspaper"></i>
                    <h3>News & Events</h3>
                    <p>Be updated with the news and events.</p>
                    <span>5</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-sun"></i>
                    <h3>DLT Eco System</h3>
                    <p>Expose yourself as an Expert in DLT Industry.</p>
                    <span>6</span>
                </div>
            </div>
        </div>
    </div>
</div>