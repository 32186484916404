<div class="bg-1" >
    <div id='stars'></div>  
    <div id='stars'></div>  
    <div id='stars2'></div>  
   
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
               


<div id='title'>
 
</div>

<div class="main-banner-text">
    <div id='stars'></div>  
    <div id='stars'></div>  
    <div id='stars2'></div>
  
    <app-banner-home></app-banner-home>   
    
                    <h1>A reward Token of <span>DXPERTS</span> Community</h1>
                    <p>A networking platform of global crypto & DLT communities eco-system.</p>
                    <a (click)="onClick('about')" class="btn btn-primary">Know more</a>
                </div>
            </div>
        </div>
    </div>
</div>


<app-welcome></app-welcome>

<app-about></app-about>

<app-who-we-are></app-who-we-are>

<app-cta></app-cta>

<app-skill></app-skill>

<app-funfacts></app-funfacts>

<app-how-we-work></app-how-we-work>

<app-services></app-services>

<app-why-we-different></app-why-we-different>

<app-faq></app-faq>

<app-strategy></app-strategy>

<app-partner></app-partner>



