<div id="why-we-different" class="why-we-different ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>What makes DXPERTS <span>Unique ?</span></h2>
            <p>A Global Crypto Community Network as a concept, completely decentralised, does not confined to any country or to its physical borders, 
                peer to peer networking, adding value to its every member ensuring right path of sustainability, non violance & global prosperity of humanity.</p>
        </div>
        <div class="tabset why-we-different-tabset">
            <!-- Tab 1 -->
            <input type="radio" name="tabset" id="tab1" aria-controls="speed-flexibility" checked>
            <label for="tab1">Right Tools</label>
            <!-- Tab 2 -->
            <input type="radio" name="tabset" id="tab2" aria-controls="profeesional-work">
            <label for="tab2">Right Education</label>
            <!-- Tab 3 -->
            <input type="radio" name="tabset" id="tab3" aria-controls="experienced-staff">
            <label for="tab3">Right Exposure</label>
            <!-- Tab 4 -->
            <input type="radio" name="tabset" id="tab4" aria-controls="custom-support">
            <label for="tab4">Right Context</label>
            <div class="tab-panels">
                <div id="speed-flexibility" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-img1.png" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Right Tools </h3>
                                <p>To develop DLT resources, capacities as a distributed international cooperation through hosting meetings & events for greater networking to its members.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Learn & aquire right knowledge</li>
                                    <li><i class="fas fa-check"></i> Analyze & classify knowledge</li>
                                    <li><i class="fas fa-check"></i> Sort available types of Knowledge</li>
                                    <li><i class="fas fa-check"></i> Be equipped with the right knowledge</li>
                                    <li><i class="fas fa-check"></i> Make stronger global crypto community</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="profeesional-work" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-img2.png" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Education</h3>
                                <p>Provide opportunities to learn, engage, contribute & promote Communities core values within the DLT eco system.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Applying right knowledge</li>
                                    <li><i class="fas fa-check"></i> Understadning implications of right Knowledge</li>
                                    <li><i class="fas fa-check"></i> Advance research in right knowledge</li>
                                    <li><i class="fas fa-check"></i> Spread, teach & improve applying right knowledge</li>
                                    <li><i class="fas fa-check"></i> Create sustainable knowledge base</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="experienced-staff" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-img3.png" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Exposure</h3>
                                <p>Encourage innovation, R&D, nurture & host, incubate new porjects, proposals thorugh online/offline collaborations.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i>More opportunities at difference scenarios</li>
                                    <li><i class="fas fa-check"></i>Unmasking the facts at different levels</li>
                                    <li><i class="fas fa-check"></i>Analyse the need and cause at different scenarios</li>
                                    <li><i class="fas fa-check"></i>To be aquainted the knowledge within local scenarios</li>
                                    <li><i class="fas fa-check"></i>To be a resource to the global crypto community</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="custom-support" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-img4.png" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Right Context</h3>
                                <p>Be updated with innovation, be a part of hosting new projects & proposals in the global crypto industry.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Exposure of fragmented context</li>
                                    <li><i class="fas fa-check"></i> Exposure of cicumstantial context</li>
                                    <li><i class="fas fa-check"></i> Exposure of event based context</li>
                                    <li><i class="fas fa-check"></i> Exposure of fact based context</li>
                                    <li><i class="fas fa-check"></i> Exposure of interdependence & connections</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>