<section class="how-work-area pt-100 pb-70">
    <div id="how-we-work" class="container">
        <div class="section-title">
            <h2>DXPERTS LFT and how does it  <span> Works ?</span></h2>
            <p>Limited Fungible Token (LFT) is a native token of DXPERTS Blockchain Network having provision of entering into smart contract 
                to activate users profile, company, project & Groups in DXPERTS professional network DAapp. LFT's are auctionable & transferable Tokens.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-info-circle"></i>
                    </div>
                    <h3>Buy LFT </h3>
                    <p>LFT's are like DPage, DCompany, DProjects & DGroup can be purchased from DXPERTS Wallet, DEFI, DEX or via third party gateways of Dxperts Blockchain.
                       Specific LFT is a must to exhibit your profile, company, project,group or to avail services within DXPERTS DApp of DXPERTS professional Network.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-drafting-compass"></i>
                    </div>
                    <h3>Lock LFT in DXPERTS DApp</h3>
                    <p>The user should lock specific LFT via smart contract within DApp as long as the user intends to exhibit their presence within community.
                       All exhibited profiles, companies, projects, groups are eligible to receive 7star Ratings from dxperts community members resulting their popularity Grid.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="far fa-paper-plane"></i>
                    </div>
                    <h3>LFT's value and its Scope</h3>
                    <p>LFT's are limited in numbers and as the desire to have own profile, company or projects in DApp grows so the demand of LFT's drives the market value.
                       Upon the expiration of DApp LFT smart Contract, the owner has the option to continue or liquidate its value based on market demand and supply conditions.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"></div>
</section>