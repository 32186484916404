<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container">
        <a class="navbar-brand" routerLink="/"><span class="home-center-banner"> <img src="assets/img/dstar_logo.png" class="main-logo" alt="image"></span></a>
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><span class="nav-link" ><a style="color: #b9b9b9;" href="https://dxperts.network"><i class="fas fa-home"></i></a></span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('welcome')"><i class="fas fa-bullseye"></i></span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('about')">DXPERTS</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('dstar-uses')"><i class="fab fa-adn"></i></span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('cta')"><i class="fas fa-file-download"></i></span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('skill')">LFT</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('services')"><i class="fas fa-th"></i></span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('why-we-different')"><i class="fas fa-crown"></i></span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('faq')"><i class="fas fa-question-circle"></i></span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('strategy')">Blockchain</span></li>
               
            </ul>
            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('home')">Home</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('welcome')">Object</span></li>                
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('about')">DXPERTS</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('dstar-uses')">Advantage</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('cta')">Fact Sheet download</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('skill')">LFT</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('services')">Services</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('why-we-different')">Why Us</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('faq')">FAQ</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('strategy')">Blockchain</span></li>            
               
            </ul>
        </div>
    </div>
</nav>


