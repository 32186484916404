<section class="funfacts-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="far fa-address-card"></i>
                    <h3><span [countUp]="360000">00</span></h3>
                    <p>Profiles Only</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="far fa-building"></i>
                    <h3><span [countUp]="360000">00</span></h3>
                    <p>Companies only</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-file-alt"></i>
                    <h3><span [countUp]="360000">00</span></h3>
                    <p>Projects Only</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-user"></i>
                    <h3><span [countUp]="360000">00</span></h3>
                    <p>Group LF Token </p>
                </div>
            </div>
        </div>
    </div>
</section>




