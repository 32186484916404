<div id="cta" class="cta-area ptb-100">
    <div class="container">
        <div class="cta-content">
            <div class="section-title mb-0">
                <h4>Eager to join the DXPERTS network ?</h4>
                <h2></h2>
                <p>DXPERTS Community aka DLT EXPERTS professional Network</p>
                <a (click)="onClick('contact')" class="btn btn-primary" href="https://dxperts.org"  target="_blank">Visit DApp</a>
                <br>
                <br>
                <br>
                <br>
                <br>
                <h4>Want to know more about DStar Reward Token ?</h4>
                <h2></h2>
                <p>DXPERTS DStar Fact Sheet</p>
                <a (click)="onClick('contact')" class="btn btn-primary view-work" href="https://dstar.network/dstarfactsheet.pdf"  target="_blank"> Download </a>
                <br>
                <br>
                <br>
                <br>
                <br>
                <h4>Want to know more about DXPERTS & its scope ?</h4>
                <h2></h2>
                <p>A Community forum of DXPERTS Blockhain network</p>
                <a (click)="onClick('work')" class="btn btn-primary" href="https://dxpertscommunity.com"  target="_blank">Visit Forum</a>
            </div>
        </div>
    </div>
</div>


