<div class="partner-area ptb-70 bg-fffdfd">
    <div class="container">
        <div class="cta-content">
            <div class="section-title mb-0">
                <h4>Crypto Assets accepted in DXPERTS Network</h4>
            </div>
            <br>
            <br>
            <br>
        <div class="partner-slides">
            <owl-carousel-o [options]="partnerSlides">
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="https://bitcoin.org/en/" target="_blank"><img src="assets/img/btc.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="https://ethereum.org/en/" target="_blank"><img src="assets/img/eth.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="https://tether.to/" target="_blank"><img src="assets/img/usdt.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="https://www.binance.com/en" target="_blank"><img src="assets/img/bnb.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="https://polkadot.network/" target="_blank"><img src="assets/img/dot.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="https://cardano.org/" target="_blank"><img src="assets/img/ada.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="https://solana.com/" target="_blank"><img src="assets/img/sol.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="https://eos.io/" target="_blank"><img src="assets/img/eos.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="https://tron.network/" target="_blank"><img src="assets/img/trx.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="https://xrpl.org/" target="_blank"><img src="assets/img/xrp.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="https://www.stellar.org/" target="_blank"><img src="assets/img/xlm.png" alt="partner"></a>
                    </div>
                </ng-template>
                </owl-carousel-o>
        </div>
    </div>
</div>