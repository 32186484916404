<div id="faq" class="faq-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Frequently Asked <span>Question</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <accordion>
                        <accordion-group heading="I am a beginner & I have no idea about Crypto space. How does joining DXPERTS community help me out ? ">
                            <p>At present crypto related information’s are so much scattered online & many beginners are confused about reliable information related crypto projects. 
                                A beginner can be an observer and have an access to participate in most of the features & acquire knowledge via must trusted resources by his peers.</p>
                        </accordion-group>
                        <accordion-group heading="I have heard much about crypto and have no idea how to get into crypto space. How does joining DXPERTS community help me out ? ">
                            <p>Providing basic information of each crypto asset and its uniqueness is a major help to the beginners. Most of the DXPERTS features like profile, company, 
                               projects & groups etc., are having 7 star ratings which are rated by DXPERTS members. Every member who has participated providing the star rating
                               does receives DStar tokens as a reward compensating the time spent for the research & efforts to arrive at a certain conclusion. The higher the 
                               ratings received by the users represents top position of the grid & the lower rating profiles are followed in ascending order reflecting the trust, 
                               reliability & leadership claims in the crypto space & this gives you more confidence to further explore in that particular space. Member ratings on each 
                               features shall provide a substantial reliable evaluation & a strong reference to the new comers to make their own decision.</p>
                        </accordion-group>
                        <accordion-group heading="I am having domain knowledge professional and have realized that future is DLT Industry. I want get into DLT space. How does joining DXPERTS 
                        Community help me out ?">
                            <p>It’s very important to realize that DLT is the future & after a decade now, it is being recognized as a promising future and many main stream professionals and 
                                companies is getting into this DLT space. Upon listing your domain expertise in your membership profile, new project teams shall contact you for the offers and 
                                seek your consultation. Otherwise you can connect them offering your services. DXPERTS core feature Networking & Events provide a platform to present your expertise, 
                                connect with members of global crypto Community.</p>
                        </accordion-group>
                        <accordion-group heading="I am a professional and looking for opportunities. How does joining DXPERTS Community help me out ? ">
                            <p>In DXPERTS we do have star ratings to each profile & these ratings given by the peers would increase your reliability within the community. People who has availed your  
                               services shall rate you based on their experience and this is the Green Key for you to go ahead with your passion / dream. Profile ratings shall provide trust & credibility, 
                               thus creating a personal Brand. Many new project postings shall be offered by upcoming / existing new projects and you can apply to them & negotiate your benifits directly 
                               with project offering experts. DXPERTS community is a facilitator to connect professionals and offer providers seamlessly. </p>
                        </accordion-group>
                        <accordion-group heading="I have a good idea/project and I need support to make it realize. How does joining DXPERTS Community help me out ? ">
                            <p>In DXPERTS we do have star ratings to each profile & these ratings given by the peers would increase your reliability within the community. People who interacting 
                                with you shall rate your profile based on their experience you had with them and this is the Green Key for you to go ahead with your passion/dream. Profile ratings shall 
                                provide trust & credibility, thus creating a personal Brand.  Once you are confident enough you can announce your idea/project in DXPERTS community. </p>
                        </accordion-group>
                        <accordion-group heading="I already have started a crypto project and I do need more funding to develop the same. How does joining DXPerts Community help me out ? ">
                            <p>DXPERTS Community has Project profile feature and exhibiting your project makes you to in touch with international crypto space globally and provide more followers providing 
                               engagement. The Buzz of your project value proposition has to get attention of VC / Investors DXPERTS Community. Besides we do provide project pitch events 
                               as one of the core feature of DXPERTS Community.</p>
                        </accordion-group>
                        <accordion-group heading="I have an existing crypto project and have gained enough traction locally and want to seek more international presence. How does joining DXPerts Community help me out ? ">
                            <p>DXPERTS is a global crypto Community & your Project profile Page shall keep you present in international space. Group Feature is also has 7 star rating feature where members do get DStar Token 
                               to rate your group and thus resulting your group on top of the grid providing more exposure to increase your following base. You also can reach relevant professionals to associate to expand your project on mutual understadnings. </p>
                        </accordion-group>
                    </accordion>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq-img.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>