<footer class="footer-area">
    <div class="container">
        <h4>Welcome to DXPERTS Professional network</h4>
        <br>
        <br>
        <a (click)="onClick('contact')" class="btn btn-primary" href="https://dxperts.org"  target="_blank">Click here to join</a>
        <br><br>
        <h3> <a class="navbar-brand" routerLink="#"><span class="home-center-banner"> <img src="assets/img/dstar_logo.png" class="main-logo" alt="image"></span></a></h3>
        <ul>
            <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
            <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
            <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
            <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
            <li><a href="#" target="_blank" class="fab fa-skype"></a></li>
        </ul>
        <p>Copyright <i class="far fa-copyright"></i>DSTAR. 2021 All Rights Reserved.</p>
    </div>
</footer>

<app-demo-sidebar></app-demo-sidebar>

<ngx-scrolltop></ngx-scrolltop>







