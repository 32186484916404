<div id="about" class="about-area ptb-100">
    <div class="container">
        <div class="section-title"> 
            <h2>Why choose  <span>  DXPERTS  ?</span></h2>
            <p>DXPERTS is a dream come true professional network & is a basic need of every Crypto Enthusiast. Decentralized network driven by its community members.
                Dxperts Community does not compete with any person, product or organization. It embraces all levels of crypto Enthusiasts, providing them an equal common platform 
                to help each other shaping DLT industries Future.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>Our success formula is <span> Your Growth</span></h2>
                        <p>A Dream Platform of being a upfront core community member and an opportunity to witness a game changing milestone of DLT revolution by engaging in :</p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Profiles</li>
                        <li><i class="fas fa-check"></i>Companies</li>
                        <li><i class="fas fa-check"></i>Projects</li>
                        <li><i class="fas fa-check"></i>Groups</li>
                        <li><i class="fas fa-check"></i>Jobs</li>
                        <li><i class="fas fa-check"></i>News</li>
                        <li><i class="fas fa-check"></i>Polls</li>
                        <li><i class="fas fa-check"></i>Events</li>
                        <li><i class="fas fa-check"></i>Resources</li>
                        <li><i class="fas fa-check"></i>Announcements</li>
                        <li><i class="fas fa-check"></i>Advertisements</li>
                        <li><i class="fas fa-check"></i>Services</li>
                    </ul>
                    <p>DXPERTS network community do believe in delivering a real value to its members by providing real solutions being unique and outstanding in the DLT industry.  </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/img/about.jpg" alt="about">
                    <div class="video-btn">
                        <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="video-popup">
    <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="#" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal>
</div>