<div id="services" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>DXPERTS network <span>Services</span></h2>
            <p>A Decentralised psychological infrastructure backed by crypto communities having its presence globally without any physical boundries.
                 A community having given powers directly to its each member to make a proposal, vote, make decision influencing to shape the future of Distributed Ledger Technologies & beyond.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/pagegold.png" alt="services-img">
                        <div class="icon">
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Profile Pages</h3>
                        <p>Position yourself as a unique professional & be recognised within Global Crypto Community.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/compgold.png" alt="services-img">
                        <div class="icon">
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Companies</h3>
                        <p>Position your company as a front lead leader & be recognised within Global Crypto Community.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/projgold.png" alt="services-img">
                        <div class="icon">
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Projects</h3>
                        <p>Position your project as a unique value proposition & be at the front arena within Global Crypto Community.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/grupgold.png" alt="services-img">
                        <div class="icon">
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Groups</h3>
                        <p> Increase your following base,be in constant touch with them & be at the front arena within Global Crypto Community.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/jobsgold.png" alt="services-img">
                        <div class="icon">
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Jobs</h3>
                        <p> Explore more opportunities & get more assignments with aspiring project developments within Global Crypto Community.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/newsgold.png" alt="services-img">
                        <div class="icon">
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>News</h3>
                        <p> Be updated with the latest global DLT related news within a click & stay top of the events within Global Crypto Community.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/pollgold.png" alt="services-img">
                        <div class="icon">
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Polls</h3>
                        <p> Understand DLT industry market pulse and opinions & witness the trend projections within Global Crypto Community.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/evntgold.png" alt="services-img">
                        <div class="icon">
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Events</h3>
                        <p> Expose yourself to the top notch DLT industry related events via offline and online getherings within Global Crypto Community.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/resogold.png" alt="services-img">
                        <div class="icon">
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Resources</h3>
                        <p> Learn & update yourself with best knowledge & resources of DLT industry for free available within Global Crypto Community.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/anntgold.png" alt="services-img">
                        <div class="icon">
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Announcements</h3>
                        <p> Update your project major mile stone achienvements & reach to larger fan base available within Global Crypto Community.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/advtgold.png" alt="services-img">
                        <div class="icon">
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Advertisements</h3>
                        <p> Promote, market your product & accelerate your reach to the larger like minded audience within Global Crypto Community.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/srvcgold.png" alt="services-img">
                        <div class="icon">
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Services</h3>
                        <p> Promote, market your services & attract potential clients to avail your services within Global Crypto Community.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>