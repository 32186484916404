<div id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h4>Dxperts Professional network</h4>
            <h2>Objective of  <span>  DXPERTS</span></h2>
            <p>To be a Service platform by introducing, educating, facilitating reources via training, workshops, connecting, hosting networking events to its members as a one stop solution for news and media promotions.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-address-card"></i>
                    </div>
                    <h3>Membership</h3>
                    <p>Position yourself as an Expert or a Professional Company within DXPERTS global community.</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-star-half-alt"></i>
                    </div>
                    <h3>Star Ratings</h3>
                    <p> Receive star ratings to your Profile or company by network peers and be at the top of pinnacle.</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-award"></i>
                    </div>
                    <h3>Recognition</h3>
                    <p> Stand out from the crowd being unique & grow along with the future of DLT Eco System.</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape2"></div>
</div>