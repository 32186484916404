<div id="strategy"  class="strategy-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-image">
                    <img src="assets/img/about-strategy.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <h4>What is DXPERTS blockchain & DXPERTS Community ?</h4>
                        <h2>What is <span>DXP Token ?</span></h2>
                        <p>DXPERTS Professional Network Community is a DApp built on DXPERTS Blockchain Network. DXP is the core utility token of DXPERTS Blockchain network and 
                           is widely used in the DXPERTS eco syste. DXPERTS Blockchain is </p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fa fa-check"></i>DPOS Consensus Mechanism</li>
                        <li><i class="fa fa-check"></i>Decentralised ORG Structure</li>
                        <li><i class="fa fa-check"></i>Bridges</li>
                        <li><i class="fa fa-check"></i>Gateways</li>
                        <li><i class="fa fa-check"></i>Wallet</li>
                        <li><i class="fa fa-check"></i>DEX</li>
                    </ul>
                    <p> DStar is a collatoral asset of DXPERTS Blockchain Network. It is 100% backed by DXP coin via smart contract on the DXPERTS Blockchain.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"></div>
</div>